import { Component } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faEnvelope, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { MatAccordion, MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-support-dialog',
  standalone: true,
  imports: [FaIconComponent, MatButton, MatAccordion, MatExpansionPanel, MatExpansionModule],
  templateUrl: './support-dialog.component.html',
  styleUrl: './support-dialog.component.scss'
})
export class SupportDialogComponent {
  protected readonly faEnvelope = faEnvelope;
  protected readonly faTimes = faTimes;

  constructor(private _dialogRef: MatDialogRef<SupportDialogComponent>) {}

  closeDialog(): void {
    this._dialogRef.close();
  }
}
