<div>
  <!-- Input field for address search -->
  <mat-form-field class="w-full" appearance="outline" *ngIf="!manuallyInputting">
    <mat-label>Vehicle Location</mat-label>
    <input type="text" matInput [formControl]="searchControl" [id]="inputId" [placeholder]="placeholderText" />
    <mat-hint *ngIf="hintText">{{ hintText }}</mat-hint>
    <mat-error *ngIf="searchControl.invalid">Please select a valid location</mat-error>
  </mat-form-field>

  <!-- Mat-select dropdown for resolved addresses -->
  <mat-form-field
    class="w-full animate__animated animate__fadeIn"
    appearance="outline"
    *ngIf="!manuallyInputting && filteredAddresses.length">
    <mat-label>Select an Address ({{ filteredAddresses.length }} Matches)</mat-label>
    <mat-select (selectionChange)="onSelectAddress($event)">
      <!-- Resolved addresses -->
      <mat-option *ngFor="let address of filteredAddresses" [value]="address">
        {{ address.suggestion }}
      </mat-option>

      <!-- Option for manual entry -->
      <mat-option [value]="{ manualEntry: true }">
        <fa-icon class="text-blue-500" [icon]="faInfoCircle"></fa-icon>
        Location not listed, click to manually enter.
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Manual entry input field -->
  <mat-form-field class="w-full" appearance="outline" *ngIf="manuallyInputting">
    <mat-label>Manual Location Entry</mat-label>
    <input type="text" matInput [formControl]="searchControl" (blur)="onManualInputBlur()" [id]="inputId" [placeholder]="placeholderText" />
    <mat-hint>Please manually enter the full address.</mat-hint>
  </mat-form-field>
</div>
