<div class="animate__animated animate__fadeIn">
  <!-- Hero Section -->
  <section class="w-full bg-gradient-to-t from-blue-500 to-blue-900 text-white">
    <div class="mx-auto max-w-screen-xl px-4 py-5 sm:px-6 lg:px-8">
      <div class="text-center">
        <h1 class="m-0 text-3xl font-extrabold leading-tight sm:text-5xl">Tyre Booking Request</h1>
        <p class="mt-4 sm:mt-2 sm:text-xl">Please complete the form below and we will be in touch to confirm your appointment.</p>
      </div>
    </div>
  </section>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="absolute -z-10">
    <path
      fill="#009fe3"
      fill-opacity="1"
      d="M0,288L80,272C160,256,320,224,480,224C640,224,800,256,960,250.7C1120,245,1280,203,1360,181.3L1440,160L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
  </svg>

  <div class="page-wrapper z-0">
    <!--Vehicle/Trailer Preface & Registration / Trailer No Entry-->
    <div *ngIf="!vehicleConfirmed" class="flex justify-center py-8">
      <div
        class="mx-auto w-full sm:w-auto max-w-screen-xl rounded-lg border border-gray-300 bg-white p-4 text-center shadow-lg sm:p-6 lg:p-8">
        <!-- Vehicle/Trailer Selection -->
        <div *ngIf="(bookTrailer | async) === null" class="animate__animated animate__fadeIn">
          <h2 class="text-3xl font-extrabold text-gray-700 sm:text-4xl">Start Your Booking</h2>
          <p class="mb-4 text-gray-600 text-lg sm:text-xl">Select a Booking Type</p>
          <div class="flex flex-col justify-center gap-6 sm:flex-row">
            <button
              class="flex items-center justify-center gap-2 rounded-lg bg-blue-600 px-6 py-3 text-white shadow-md transition duration-150 ease-in-out hover:scale-105 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50"
              (click)="setTrailerStatus(false)">
              <fa-icon [icon]="faCar" class="text-lg"></fa-icon>
              <span class="text-base font-medium">Book for a Vehicle</span>
            </button>
            <button
              class="flex items-center justify-center gap-2 rounded-lg bg-green-600 px-6 py-3 text-white shadow-md transition duration-150 ease-in-out hover:scale-105 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
              (click)="setTrailerStatus(true)">
              <fa-icon [icon]="faTrailer" class="text-lg"></fa-icon>
              <span class="text-base font-medium">Book for a Trailer</span>
            </button>
          </div>
        </div>
        <div *ngIf="(bookTrailer | async) !== null">
          <!-- Vehicle Registration Input -->
          <form [formGroup]="registrationForm" (ngSubmit)="confirmRegistration()">
            <div class="flex flex-col items-center justify-center p-6 text-center animate__animated animate__fadeIn">
              <h2 class="text-3xl font-extrabold text-gray-700 sm:text-4xl">
                {{ (bookTrailer | async) ? 'Vehicle and Trailer Information' : 'Vehicle Registration' }}
              </h2>
              <p class="text-gray-600 text-lg sm:text-xl mb-4">
                {{
                  (bookTrailer | async)
                    ? 'Please enter the registration of the vehicle pulling the trailer and the trailer number below.'
                    : 'Enter the registration of the vehicle you would like to book a service for.'
                }}
              </p>

              <div class="w-full sm:w-3/4 lg:w-1/2 mb-4">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Vehicle Registration</mat-label>
                  <input
                    matInput
                    formControlName="registration"
                    type="text"
                    placeholder="AB12 CDE"
                    maxlength="8"
                    pattern="[A-Za-z0-9 ]+"
                    appUppercase />
                  <mat-error *ngIf="registrationForm.get('registration')?.invalid">Vehicle registration is required</mat-error>
                </mat-form-field>
              </div>

              <div class="w-full sm:w-3/4 lg:w-1/2 mb-4" *ngIf="bookTrailer | async">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label>Trailer Number</mat-label>
                  <input matInput formControlName="trailerNumber" maxlength="15" />
                  <mat-error *ngIf="registrationForm.get('trailerNumber')?.invalid">Trailer number is required</mat-error>
                </mat-form-field>
              </div>

              <div class="w-full sm:w-3/4 lg:w-1/2 mb-4">
                <button
                  [disabled]="registrationForm.invalid || gettingVehicle"
                  class="w-full flex items-center justify-center gap-2 rounded-lg bg-blue-600 px-6 py-3 text-white shadow-md transition duration-150 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-opacity-50 disabled:bg-gray-400"
                  type="submit">
                  <fa-icon [icon]="faCheck" class="text-lg" *ngIf="!gettingVehicle"></fa-icon>
                  <span class="ml-2 text-base font-medium">{{ gettingVehicle ? 'Confirming...' : 'Confirm' }}</span>
                </button>
              </div>

              <div class="w-full sm:w-3/4 lg:w-1/2">
                <button
                  class="w-full flex items-center justify-center gap-2 rounded-lg bg-white px-6 py-3 text-black transition duration-150 ease-in-out hover:bg-gray-100 border border-gray-300 focus:outline-none focus:ring-opacity-50"
                  (click)="setTrailerStatus(null)"
                  type="button">
                  <span class="ml-2 text-base font-medium">Go Back</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!--Rest of Form-->
    <div>
      <div class="mx-auto max-w-4xl">
        <form [formGroup]="jobDetails" (ngSubmit)="submitJob()">
          <div class="flex flex-col animate__animated animate__fadeInUp" *ngIf="vehicleConfirmed">
            <div class="mb-4">
              <app-selected-vehicle [vehicleData]="activeVehicle"></app-selected-vehicle>
            </div>
            <card>
              <card-body>
                <div class="divide-y divide-gray-300">
                  <!-- Personal Details -->
                  <div>
                    <h2 class="text-xl font-extrabold text-blue-500 md:text-2xl">
                      <fa-icon [icon]="faUser"></fa-icon>
                      Contact Details
                    </h2>
                    <div class="mb-4 w-full bg-gradient-to-r from-blue-500 to-transparent h-0.5"></div>

                    <div class="grid grid-cols-1 sm:grid-cols-3 sm:gap-4">
                      <!-- Title Select Field -->
                      <mat-form-field appearance="outline">
                        <mat-label>Your Title</mat-label>
                        <mat-select formControlName="userTitle">
                          <mat-option *ngFor="let contactTitle of contactTitles" [value]="contactTitle.value">
                            {{ contactTitle.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="jobDetails.controls['userTitle'].invalid">Title is required</mat-error>
                      </mat-form-field>

                      <!-- First Name Input Field -->
                      <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Your First Name</mat-label>
                        <input matInput type="text" formControlName="userFirstName" maxlength="50" [errorStateMatcher]="matcher" />
                        <mat-error *ngIf="jobDetails.controls['userFirstName'].invalid">Forename Required</mat-error>
                      </mat-form-field>

                      <!-- Surname Input Field -->
                      <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Your Surname</mat-label>
                        <input matInput type="text" formControlName="userLastName" maxlength="50" [errorStateMatcher]="matcher" />
                        <mat-error *ngIf="jobDetails.controls['userLastName'].invalid">Surname Required</mat-error>
                      </mat-form-field>

                      <div class="col-span-full grid grid-cols-1 sm:grid-cols-2 sm:gap-4">
                        <mat-form-field class="w-full" appearance="outline">
                          <mat-label>Your Telephone No.</mat-label>
                          <input matInput type="text" formControlName="userContactNumber" [errorStateMatcher]="matcher" />
                          <mat-error *ngIf="jobDetails.controls['userContactNumber'].invalid">Enter a Valid Tel No.</mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-full" appearance="outline">
                          <mat-label>Your Email Address</mat-label>
                          <input matInput type="text" formControlName="userEmail" [errorStateMatcher]="matcher" />
                          <mat-error *ngIf="jobDetails.controls['userEmail'].invalid">Enter a Email</mat-error>
                        </mat-form-field>
                      </div>
                    </div>

                    <!-- Driver Question -->
                    <div class="col-span-full flex justify-center">
                      <div class="flex flex-col items-center text-center">
                        <label>Are you currently the driver of the vehicle?</label>
                        <mat-chip-listbox
                          aria-label="Are you the driver"
                          formControlName="userIsDriver"
                          class="mt-2 flex justify-center space-x-4">
                          <mat-chip-option [value]="true" class="text-center">Yes</mat-chip-option>
                          <mat-chip-option [value]="false" class="text-center">No</mat-chip-option>
                        </mat-chip-listbox>
                        <mat-error *ngIf="jobDetails.get('userIsDriver')?.invalid && jobDetails.get('userIsDriver')?.touched" class="mt-2">
                          Please confirm if you are currently the driver
                        </mat-error>
                      </div>
                    </div>

                    <!-- Driver Details (conditionally shown) -->
                    <div *ngIf="jobDetails.get('userIsDriver')?.value === false" class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                      <!-- Driver's Title -->
                      <mat-form-field appearance="outline">
                        <mat-label>Driver's Title</mat-label>
                        <mat-select formControlName="driverTitle">
                          <mat-option *ngFor="let contactTitle of contactTitles" [value]="contactTitle.value">
                            {{ contactTitle.name }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="jobDetails.get('driverTitle')?.invalid">Driver's title is required</mat-error>
                      </mat-form-field>

                      <!-- Driver's First Name -->
                      <mat-form-field appearance="outline">
                        <mat-label>Driver's First Name</mat-label>
                        <input matInput formControlName="driverFirstName" maxlength="50" />
                        <mat-error *ngIf="jobDetails.get('driverFirstName')?.invalid">Driver Name Required</mat-error>
                      </mat-form-field>

                      <!-- Driver's Last Name -->
                      <mat-form-field appearance="outline">
                        <mat-label>Driver's Last Name</mat-label>
                        <input matInput formControlName="driverLastName" maxlength="50" />
                        <mat-error *ngIf="jobDetails.get('driverLastName')?.invalid">Driver Surname Required</mat-error>
                      </mat-form-field>

                      <!-- Driver's Contact Number and Email Address on Same Row -->
                      <div class="col-span-full md:col-span-3 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <mat-form-field appearance="outline">
                          <mat-label>Driver's Contact Number</mat-label>
                          <input matInput formControlName="driverContactNo" maxlength="15" />
                          <mat-error *ngIf="jobDetails.get('driverContactNo')?.invalid">Enter a valid contact number</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Driver's Email Address</mat-label>
                          <input matInput formControlName="driverEmail" maxlength="100" />
                          <mat-error *ngIf="jobDetails.get('driverEmail')?.invalid">Enter a valid email address</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </card-body>
            </card>

            <!--Vehicle Details-->
            <!-- Vehicles FormArray -->
            <div formArrayName="vehicles" class="mt-8">
              <div *ngFor="let vehicleGroup of vehiclesFormArray.controls; let i = index" [formGroupName]="i">
                <mat-horizontal-stepper #stepper class="rounded-md border border-gray-300 shadow" [linear]="true">
                  <!--Vehicle Availability-->
                  <mat-step [completed]="vehicleAvailabilityComplete(i)">
                    <ng-template matStepLabel>
                      <fa-icon [icon]="faCalendar" class="sm:mr-2"></fa-icon>
                      <span class="hidden sm:inline-block font-bold">Availability</span>
                    </ng-template>

                    <div class="sm:mt-2">
                      <h2 class="mb-4 inline-block text-xl font-extrabold text-blue-500 sm:hidden sm:text-2xl">Vehicle Availability</h2>

                      <!-- Booking Type  & Availability Selection -->
                      <div class="mb-4 rounded-lg border p-4">
                        <h3 class="mb-2 text-lg font-semibold">Preferred Booking Type</h3>
                        <p class="mb-4">
                          Choose your preferred service: a mobile fitting at your location or a visit to one of our Service Providers.
                        </p>

                        <div class="flex flex-col justify-center gap-4 sm:flex-row">
                          <!-- Mobile Fitting Option -->
                          <div
                            class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 transition-all"
                            [ngClass]="{
                              'border-blue-600 bg-blue-100': vehicleGroup.get('bookingType')?.value === 'Mobile Fitting',
                              'border-gray-300 hover:border-blue-600': vehicleGroup.get('bookingType')?.value !== 'Mobile Fitting'
                            }"
                            (click)="vehicleGroup.get('bookingType')?.setValue('Mobile Fitting')">
                            <fa-icon [icon]="faTruck" class="text-2xl"></fa-icon>
                            <span class="text-base font-medium sm:text-lg">Mobile Fitting</span>
                            <span class="text-center text-sm text-gray-600 sm:text-base">We'll come to your location</span>
                          </div>

                          <!-- Drive In Option -->
                          <div
                            class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 transition-all"
                            [ngClass]="{
                              'border-blue-600 bg-blue-100': vehicleGroup.get('bookingType')?.value === 'Drive-In',
                              'border-gray-300 hover:border-blue-600': vehicleGroup.get('bookingType')?.value !== 'Drive-In'
                            }"
                            (click)="vehicleGroup.get('bookingType')?.setValue('Drive-In')">
                            <fa-icon [icon]="faWarehouse" class="text-2xl"></fa-icon>
                            <span class="text-base font-medium sm:text-lg">Drive In</span>
                            <span class="text-center text-sm text-gray-600 sm:text-base">Visit one of our Service Providers</span>
                          </div>
                        </div>
                        <mat-error *ngIf="vehicleGroup.get('bookingType')?.touched && vehicleGroup.get('bookingType')?.invalid">
                          Please select a booking type.
                        </mat-error>

                        @if (vehicleGroup.get('bookingType')?.value === 'Mobile Fitting') {
                          <!-- Address Input for Mobile Fitting -->
                          <div class="w-full mt-4">
                            <app-ideal-address-search
                              [id]="'address-search-' + i"
                              [attemptedNext]="attemptedNextStep || formSubmitted"
                              [showError]="vehicleGroup.get('vehicleLocationDetails')?.invalid"
                              (addressSelected)="setVehicleLocation($event, i)"></app-ideal-address-search>
                            <mat-error
                              *ngIf="
                                vehicleGroup.get('vehicleLocationDetails')?.touched && vehicleGroup.get('vehicleLocationDetails')?.invalid
                              ">
                              Please provide a location for mobile fitting.
                            </mat-error>
                          </div>
                        } @else if (vehicleGroup.get('bookingType')?.value === 'Drive-In') {
                          <!-- Postcode input for Drive-In -->
                          <div class="w-full mt-4">
                            <mat-form-field class="w-full" appearance="outline">
                              <mat-label>Enter the postcode for the area where you'd like a drive-in booking</mat-label>
                              <input
                                matInput
                                formControlName="driveInPostcode"
                                placeholder="E.g., AB12 3CD"
                                maxlength="8"
                                pattern="[A-Za-z0-9 ]+" />
                              <mat-error *ngIf="vehicleGroup.get('driveInPostcode')?.invalid">Please provide a valid postcode.</mat-error>
                            </mat-form-field>
                          </div>
                        }
                      </div>

                      <!-- Vehicle Availability Selection Component -->
                      @if (
                        vehicleGroup.get('bookingType')?.valid &&
                        vehicleGroup.get('driveInPostcode')?.valid &&
                        vehicleGroup.get('vehicleLocationDetails')?.valid
                      ) {
                        <div class="mb-4 rounded-lg border p-4 animate__animated animate__fadeIn">
                          <app-vehicle-availability-selection
                            [selectedBookingType]="vehicleGroup.get('bookingType')?.value"
                            [dtmContactNo]="dtmContactNo"
                            (selectionChange)="handleAvailabilitySelection($event)"></app-vehicle-availability-selection>
                        </div>

                        <mat-error
                          *ngIf="
                            vehicleGroup.get('vehicleAvailability')?.touched &&
                            vehicleGroup.get('vehicleAvailability')?.errors?.['required']
                          ">
                          Select a preferred date and time.
                        </mat-error>
                      }

                      <!-- Navigation Buttons -->
                      <div class="flex flex-col space-y-2 sm:flex-row sm:justify-between sm:space-y-0 mt-8">
                        <button mat-raised-button type="button" class="w-full sm:w-auto" (click)="stepper.previous()">Back</button>
                        <button
                          mat-raised-button
                          color="primary"
                          type="button"
                          class="w-full sm:w-auto"
                          (click)="nextStep(stepper, 'Vehicle Availability', i)">
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>

                  <!-- Work Required-->
                  <mat-step [completed]="tyreDetailsComplete(i)">
                    <ng-template matStepLabel>
                      <fa-icon [icon]="faTools" class="sm:mr-2"></fa-icon>
                      <span class="font-bold hidden sm:inline-block">Work Required</span>
                    </ng-template>
                    <div class="sm:mt-2" *ngIf="vehicleGroup.get('activeVehicle')?.value">
                      <h2 class="mb-4 inline-block text-xl font-extrabold text-blue-500 sm:hidden sm:text-2xl">Work Required</h2>

                      <!-- Selection between Tyres and Services -->
                      <div class="mb-4 rounded-lg border p-4">
                        <h3 class="mb-2 text-lg font-semibold">Select Required Service</h3>
                        <p class="mb-4">Please select if you need replacement tyres or a specific tyre-related service for your vehicle.</p>

                        <div class="flex flex-col justify-center gap-4 sm:flex-row">
                          <div
                            class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 transition-all"
                            [ngClass]="{
                              'border-blue-600 bg-blue-100': vehicleGroup.get('jobType')?.value === 'Tyres',
                              'border-gray-300 hover:border-blue-600': vehicleGroup.get('jobType')?.value !== 'Tyres'
                            }"
                            (click)="vehicleGroup.get('jobType')?.setValue('Tyres')">
                            <fa-icon [icon]="faTireFlat" class="text-2xl"></fa-icon>
                            <span class="text-base font-medium sm:text-lg">Tyres</span>
                            <span class="text-center text-sm text-gray-600 sm:text-base">Select for tyre replacements</span>
                          </div>
                          <div
                            class="flex flex-1 cursor-pointer flex-col items-center justify-center gap-2 rounded-lg border-2 p-4 transition-all"
                            [ngClass]="{
                              'border-blue-600 bg-blue-100': vehicleGroup.get('jobType')?.value === 'Services',
                              'border-gray-300 hover:border-blue-600': vehicleGroup.get('jobType')?.value !== 'Services'
                            }"
                            (click)="vehicleGroup.get('jobType')?.setValue('Services')">
                            <fa-icon [icon]="faTools" class="text-2xl"></fa-icon>
                            <span class="text-base font-medium sm:text-lg">Service</span>
                            <span class="text-center text-sm text-gray-600 sm:text-base">
                              Select for tyre-related services such as repairs
                            </span>
                          </div>
                        </div>
                      </div>

                      <!-- Vehicle Schematic -->
                      @if (vehicleGroup.get('jobType')?.value) {
                        <div class="mb-4 rounded-lg border p-4 animate__animated animate__fadeIn">
                          <h3 class="mb-2 text-lg font-semibold">Select Tyre Positions For This Job</h3>
                          <p class="mb-4 text-sm">
                            Click on the tyres of the vehicle schematic below that require attention. You can then provide the required Tyre
                            Size and Defect or your required service.
                          </p>
                          <div class="w-full">
                            <app-interactive-schematic
                              [vehicle]="vehicleGroup.get('activeVehicle')?.value"
                              [vehicleGroup]="vehicleGroup"
                              [jobType]="vehicleGroup.get('jobType')?.value"></app-interactive-schematic>
                            <mat-error *ngIf="showJobItemsError[i]">Please add at least one tyre to the job</mat-error>
                          </div>
                        </div>
                      }

                      <!-- Additional Controls for Tyres -->
                      <div *ngIf="vehicleGroup.get('jobType')?.value" class="mb-4 rounded-lg border p-4">
                        <h3 class="mb-2 text-lg font-semibold">Additional Tyre Details</h3>
                        <div [ngClass]="{ 'md:grid-cols-3': !isTruck, 'md:grid-cols-2': isTruck }" class="grid grid-cols-1 gap-4">
                          <!-- Tyre Type -->
                          <ng-container *ngIf="!isTruck">
                            <mat-form-field appearance="outline" class="col-span-1">
                              <mat-label>Vehicle Tyre Type</mat-label>
                              <mat-select formControlName="tyreType">
                                <mat-option value="Standard">Standard</mat-option>
                                <mat-option value="Runflat">Runflat</mat-option>
                                <mat-option value="Unknown">Not Sure</mat-option>
                              </mat-select>
                              <mat-error *ngIf="vehicleGroup.get('tyreType')?.invalid">Tyre type is required</mat-error>
                            </mat-form-field>
                          </ng-container>

                          <!-- Application Type -->
                          <mat-form-field appearance="outline" class="col-span-1">
                            <mat-label>Application Type</mat-label>
                            <mat-select formControlName="applicationType">
                              <mat-option value="Summer">Summer (Standard)</mat-option>
                              <mat-option value="All Terrain">All Terrain</mat-option>
                              <mat-option value="Cross Climate / All Season">Cross Climate / All Season</mat-option>
                              <mat-option value="Highway Terrain">Highway Terrain</mat-option>
                              <mat-option value="Mud and Snow">Mud and Snow</mat-option>
                              <mat-option value="Mud Terrain">Mud Terrain</mat-option>
                              <mat-option value="Winter">Winter</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vehicleGroup.get('applicationType')?.invalid">Application type is required</mat-error>
                          </mat-form-field>

                          <!-- Spare Wheel -->
                          <mat-form-field appearance="outline" class="col-span-1">
                            <mat-label>Spare Wheel Held?</mat-label>
                            <mat-select formControlName="spareWheel">
                              <mat-option [value]="true">Yes</mat-option>
                              <mat-option [value]="false">No</mat-option>
                            </mat-select>
                            <mat-error *ngIf="vehicleGroup.get('spareWheel')?.invalid">Spare Wheel confirmation required</mat-error>
                          </mat-form-field>
                        </div>

                        <div class="col-span-full">
                          <mat-form-field class="w-full" appearance="outline">
                            <mat-label>Current Vehicle Mileage</mat-label>
                            <input matInput formControlName="vehicleMileage" maxlength="7" />
                            <mat-error *ngIf="vehicleGroup.get('vehicleMileage')?.invalid">Vehicle mileage is required</mat-error>
                          </mat-form-field>
                        </div>
                      </div>

                      <!-- Navigation Buttons -->
                      <div class="mt-4 flex flex-col justify-between space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row">
                        <button mat-raised-button type="button" class="w-full sm:w-auto" (click)="stepper.previous()">Back</button>
                        <button
                          mat-raised-button
                          color="primary"
                          type="button"
                          class="w-full sm:w-auto"
                          (click)="nextStep(stepper, 'Tyre & Defect Details', i)">
                          <fa-icon [icon]="faChevronDoubleRight"></fa-icon>
                          Next
                        </button>
                      </div>
                    </div>
                  </mat-step>

                  <!-- Comments Section -->
                  <mat-step>
                    <ng-template matStepLabel>
                      <fa-icon [icon]="faNotes" class="sm:mr-2"></fa-icon>
                      <span class="hidden sm:inline-block font-bold">Comments</span>
                    </ng-template>
                    <div class="sm:mt-2">
                      <h2 class="mb-4 inline-block text-xl font-extrabold text-blue-500 sm:hidden sm:text-2xl">Comments</h2>
                      <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
                        <mat-form-field class="w-full" appearance="outline">
                          <mat-label>Order Number (If known)</mat-label>
                          <input matInput formControlName="customerOrderNumber" maxlength="30" />
                        </mat-form-field>

                        <mat-form-field class="w-full" appearance="outline">
                          <mat-label>Defect Number (If known)</mat-label>
                          <input matInput formControlName="customerDefectNumber" maxlength="30" />
                        </mat-form-field>
                      </div>

                      <mat-form-field class="w-full" appearance="outline">
                        <mat-label>Comments</mat-label>
                        <textarea matInput formControlName="comments" maxlength="150" rows="3"></textarea>
                        <mat-hint>150 Characters Max</mat-hint>
                      </mat-form-field>

                      <div class="flex flex-col space-y-2 mt-2 sm:flex-row sm:justify-between sm:space-y-0">
                        <button mat-raised-button type="button" class="w-full sm:w-auto" (click)="stepper.previous()">Back</button>
                        <app-save-button
                          class="w-full sm:w-auto"
                          [isSaving]="isSubmitting"
                          [type]="'submit'"
                          [inProgressMessage]="'Submitting...'"
                          [buttonLabel]="'Submit Request'"></app-save-button>
                      </div>
                    </div>
                  </mat-step>
                </mat-horizontal-stepper>
              </div>

              <div class="border-t border-gray-300">
                <div *ngIf="submissionError" class="mt-8">
                  <app-alert [type]="'error'" [alertTitle]="'Error'" [alertMessage]="submissionError"></app-alert>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
