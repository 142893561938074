import { Component, Inject, OnInit } from '@angular/core';
import { TimeSlot, TimeSlotCategory } from '../vehicle-availability-selection.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { faMoon, faSunAlt } from '@fortawesome/pro-regular-svg-icons';

export interface selectedDateTimeSlot {
  date: DateTime;
  timeSlots: TimeSlot[];
  expanded?: boolean;
}

@Component({
  selector: 'app-time-slot-dialog',
  templateUrl: './time-slot-dialog.component.html',
  styleUrl: './time-slot-dialog.component.scss'
})
export class TimeSlotDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      selectedDate: DateTime;
      isDriveIn: boolean;
    },
    public dialogRef: MatDialogRef<TimeSlotDialogComponent>
  ) {
    // If a drive-in booking type is selected, then set the flag against the selected timeslots
    this.driveInAcceptance = data.isDriveIn;
  }

  public selectedDate: DateTime = this.data.selectedDate;
  public selectedDateString: string = this.selectedDate.toFormat('EEEE d LLLL');
  public noSelectionError: boolean = false;
  public selectedTimeSlots: TimeSlot[] = [];
  public showAdditionalDaySlots: boolean = false; // Track if additional day slots are shown
  public AdditionalChargeMessage: string | null = null;

  protected readonly faSunAlt = faSunAlt;
  protected readonly faMoon = faMoon;

  public driveInAcceptance: boolean = false;

  public activeCategory: TimeSlotCategory = 'day';

  timeSlots = {
    day: [
      { label: 'All Day (8:30am - 17:30pm)', id: 50 },
      { label: 'Morning (8:30am - 12:30pm)', id: 51 },
      { label: 'Afternoon (12:30pm - 17:30pm)', id: 52 },
      { label: '8:30am – 10:30am', id: 41 },
      { label: '10:30am – 12:30pm', id: 42 },
      { label: '12:30pm – 14:30pm', id: 43 },
      { label: '14:30pm – 17:30pm', id: 44 }
    ],
    night: [
      { label: '5:30pm – 10pm', id: 47 },
      { label: '10pm – 6am', id: 48 },
      { label: '6am – 8:30am', id: 49 }
    ]
  };

  filteredTimeSlots: { [key in TimeSlotCategory]: TimeSlot[] } = {
    day: [],
    night: []
  };

  ngOnInit(): void {
    this._filterTimeSlots();
    this._checkAdditionalCharges();
  }

  private _filterTimeSlots(): void {
    const now = DateTime.now();
    const twoHoursLater = now.plus({ hours: 2 });

    if (this.selectedDate.toFormat('yyyy-MM-dd') === now.toFormat('yyyy-MM-dd')) {
      this.filteredTimeSlots.day = this.timeSlots.day.filter((slot) => this._isSlotAvailable(slot, twoHoursLater));
      this.filteredTimeSlots.night = this.timeSlots.night.filter((slot) => this._isSlotAvailable(slot, twoHoursLater));
    } else {
      this.filteredTimeSlots.day = this.timeSlots.day;
      this.filteredTimeSlots.night = this.timeSlots.night;
    }
  }

  private _checkAdditionalCharges(): void {
    if (this.selectedDate.weekday === 6) {
      // Saturday
      this.AdditionalChargeMessage = 'Bookings after 12.30pm on a Saturday will incur an additional charge.';
    } else if (this.selectedDate.weekday === 7) {
      // Sunday
      this.AdditionalChargeMessage = 'Sunday bookings will incur an additional charge.';
    }
  }

  private _isSlotAvailable(slot: TimeSlot, cutoffTime: DateTime): boolean {
    const slotTime = this._getSlotTime(slot);
    return slotTime > cutoffTime;
  }

  private _getSlotTime(slot: TimeSlot): DateTime {
    const timeMatch = slot.label.match(/(\d{1,2}):(\d{2})(am|pm)/i);
    if (timeMatch) {
      const hours = parseInt(timeMatch[1], 10);
      const minutes = parseInt(timeMatch[2], 10);
      const isPM = timeMatch[3].toLowerCase() === 'pm';
      const adjustedHours = isPM ? (hours % 12) + 12 : hours % 12;
      return DateTime.fromObject({
        year: this.selectedDate.year,
        month: this.selectedDate.month,
        day: this.selectedDate.day,
        hour: adjustedHours,
        minute: minutes
      });
    }

    const rangeMatch = slot.label.match(/(\d{1,2})(am|pm)\s*–\s*(\d{1,2})(am|pm)/i);
    if (rangeMatch) {
      const startHours = parseInt(rangeMatch[1], 10);
      const startIsPM = rangeMatch[2].toLowerCase() === 'pm';
      const adjustedStartHours = startIsPM ? (startHours % 12) + 12 : startHours % 12;
      return DateTime.fromObject({
        year: this.selectedDate.year,
        month: this.selectedDate.month,
        day: this.selectedDate.day,
        hour: adjustedStartHours,
        minute: 0
      });
    }

    return DateTime.now(); // Default return if no match
  }

  public selectCategory(category: TimeSlotCategory): void {
    if (this.activeCategory !== category) {
      this.activeCategory = category;
    }
  }

  public confirmSelection(): void {
    if (this.selectedTimeSlots.length === 0) {
      this.noSelectionError = true;
      return;
    }

    // Set the drive in acceptance of each selected set
    this.selectedTimeSlots.forEach((slot) => {
      slot.driveInAcceptance = this.driveInAcceptance;
    });

    const result: selectedDateTimeSlot = {
      timeSlots: this.selectedTimeSlots,
      date: this.selectedDate
    };

    this.dialogRef.close(result);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public resetError(): void {
    this.noSelectionError = this.selectedTimeSlots.length === 0;
  }

  public toggleAdditionalDaySlots(): void {
    this.showAdditionalDaySlots = !this.showAdditionalDaySlots;
  }

  public arePrimarySlotsAvailable(): boolean {
    const primarySlotIds = [50, 51, 52];
    const primarySlots = this.filteredTimeSlots.day.filter((slot) => primarySlotIds.includes(slot.id));
    return primarySlots.length > 0;
  }
}
