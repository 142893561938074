import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GeolocationService } from '../../services/geolocation.service';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-ideal-address-search',
  templateUrl: './ideal-address-search.component.html',
  styleUrls: ['./ideal-address-search.component.scss']
})
export class IdealAddressSearchComponent implements OnInit, OnChanges, OnDestroy {
  searchControl = new FormControl();
  filteredAddresses: any[] = [];
  resolvingAddress: boolean = false;
  selectionMade: boolean = false;
  manuallyInputting: boolean = false;

  @Output() addressSelected = new EventEmitter<any>();
  @Input() showError: any;
  @Input() hintText: string = 'Enter an address or post code to search';
  @Input() id: string = '';
  @Input() attemptedNext: boolean = false;

  protected readonly faInfoCircle = faInfoCircle;

  placeholderText: string = 'Enter an Address/Postcode to begin searching...';

  private _subscriptions = new Subscription();

  constructor(private _geolocationService: GeolocationService) {}

  get inputId() {
    return `${this.id}-input`;
  }

  ngOnInit(): void {
    const valueChangesSub = this.searchControl.valueChanges
      .pipe(
        debounceTime(300), // Wait for 300ms after the user stops typing
        distinctUntilChanged(), // Ignore repeated values
        tap(() => {
          this.resolvingAddress = true; // Show loading indicator
          this.selectionMade = false; // Reset selection status
        }),
        switchMap((searchValue) => {
          if (!searchValue) {
            this.filteredAddresses = [];
            this.resolvingAddress = false;
            return [];
          }
          return this._geolocationService.searchAddress(searchValue).pipe(
            tap(() => (this.resolvingAddress = false)) // Hide loading indicator
          );
        })
      )
      .subscribe(
        (response: any) => {
          this.filteredAddresses = response?.result?.hits || [];
        },
        (error) => {
          console.error(error);
          this.resolvingAddress = false;
        }
      );

    this._subscriptions.add(valueChangesSub);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attemptedNext'] && this.attemptedNext) {
      if (!this.selectionMade) {
        this.searchControl.setErrors({ required: true });
      }
    }
  }

  onSelectAddress(event: any): void {
    if (event.value.manualEntry) {
      this._allowManualEntry();
    } else {
      this.selectionMade = true;

      const resolveSub = this._geolocationService.resolveAddress(event.value.id).subscribe(
        (data) => {
          const fullAddress = `${event.value.suggestion} (${data.result.postcode})`;
          this.searchControl.setValue(fullAddress, { emitEvent: false });

          this.addressSelected.emit({
            geolocationGUID: data.geolocationGuid,
            fullAddress: fullAddress,
            address: {
              line1: data.result.line_1,
              line2: data.result.line_2,
              line3: data.result.line_3,
              line4: data.result.post_town,
              county: data.result.county,
              postcode: data.result.postcode,
              country: data.result.country
            }
          });
        },
        (error) => {
          console.error(error);
        }
      );

      this._subscriptions.add(resolveSub);
    }
  }

  private _allowManualEntry(): void {
    this.searchControl.setValue('', { emitEvent: true });
    this.filteredAddresses = [];
    this.manuallyInputting = true;
    this.placeholderText = 'Manually enter the full address of the vehicle';
  }

  onManualInputBlur(): void {
    if (!this.selectionMade) {
      this.addressSelected.emit({
        fullAddress: this.searchControl.value
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
