<div class="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
  <div class="text-center">
    <fa-icon [icon]="faEnvelope" class="text-blue-600 text-4xl mb-4"></fa-icon>
    <h2 class="text-2xl font-extrabold text-gray-800 mb-2">Need Support?</h2>
    <p class="text-gray-600 mb-4">For any support queries, please feel free to reach out to us at the email address below.</p>

    <!-- Contact Information -->
    <div class="bg-blue-100 text-black rounded-lg p-3 my-4 font-medium align-middle">
      <fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon>
      <a href="mailto:appsupport@directtyre.com" class="hover:underline" tabindex="-1">{{ 'appsupport@directtyre.com' }}</a>
    </div>

    <!-- Close Button -->
    <button mat-stroked-button (click)="closeDialog()" class="mt-6" tabindex="-1">Close</button>
  </div>
</div>
