<div title="Vehicle Information" class="vehicle-info rounded-md py-2 px-6 b bg-blue-800 shadow" *ngIf="vehicleData">
  <div class="flex flex-col sm:flex-row items-center text-white">
    <fa-icon [icon]="getIcon(vehicleData.vehicleLayout?.vehicleClassification!)" size="2x"></fa-icon>
    <!--Standard Data Display if No Driveright Data Held-->
    <div class="mt-2 sm:mt-0 sm:ml-4 text-center sm:text-left" *ngIf="!vehicleData.vehicleMake">
      <span class="block text-lg sm:text-xl font-extrabold tracking-wide">
        {{ vehicleData.customerFleetlistVehicleReg }} - {{ vehicleData.vehicleLayout?.vehicleClassification }}
      </span>
    </div>

    <!--Driveright Data Display If Held-->
    <div class="mt-2 sm:mt-0 sm:ml-4 text-center sm:text-left" *ngIf="vehicleData.vehicleMake">
      <span class="block text-lg sm:text-xl font-extrabold tracking-wide">
        {{ vehicleData.customerFleetlistVehicleReg }} -
        {{ vehicleData.vehicleColour + ' ' + vehicleData.vehicleMake + ' ' + vehicleData.vehicleModelDr }}
      </span>
    </div>
  </div>
</div>
