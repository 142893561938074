import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DateTime } from 'luxon';
import {
  faCalendarAlt,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronUp,
  faInfoCircle,
  faShuttleVan,
  faTimes,
  faTruck
} from '@fortawesome/pro-regular-svg-icons';
import { selectedDateTimeSlot, TimeSlotDialogComponent } from './time-slot-dialog/time-slot-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface TimeSlot {
  label: string;
  id: number;
  driveInAcceptance?: boolean;
}

export type TimeSlotCategory = 'day' | 'night';

@Component({
  selector: 'app-vehicle-availability-selection',
  templateUrl: './vehicle-availability-selection.component.html',
  styleUrls: ['./vehicle-availability-selection.component.scss']
})
export class VehicleAvailabilitySelectionComponent implements OnDestroy {
  constructor(private _dialog: MatDialog) {}

  @Input() dtmContactNo?: string;
  @Input() selectedBookingType: 'Mobile' | 'Drive-In';
  @Output() selectionChange = new EventEmitter<selectedDateTimeSlot[]>();

  public currentMonth: DateTime = DateTime.now();
  public selectedTimeSlots: selectedDateTimeSlot[] = [];
  private _subscription: Subscription = new Subscription();

  protected readonly faCalendarAlt = faCalendarAlt;
  protected readonly faTimes = faTimes;
  protected readonly faShuttleVan = faShuttleVan;
  protected readonly faTruck = faTruck;
  protected readonly faChevronDoubleLeft = faChevronDoubleLeft;
  protected readonly faChevronDoubleRight = faChevronDoubleRight;
  protected readonly faInfoCircle = faInfoCircle;

  public todayStart(): DateTime {
    return DateTime.now().startOf('day');
  }

  private _emitSelection(): void {
    this.selectionChange.emit(this.selectedTimeSlots);
  }

  get daysInMonth(): (DateTime | { isPlaceholder: boolean })[] {
    const days: (DateTime | { isPlaceholder: boolean })[] = [];
    const startDay = this.currentMonth.startOf('month');
    const endDay = this.currentMonth.endOf('month');

    // Get the weekday of the first day of the month (1 = Monday, ..., 7 = Sunday)
    const firstDayOfWeek = startDay.weekday;
    // Add leading empty cells
    for (let i = 1; i < firstDayOfWeek; i++) {
      days.push({ isPlaceholder: true });
    }

    for (let day = startDay; day <= endDay; day = day.plus({ days: 1 })) {
      days.push(day);
    }

    // Add trailing empty cells
    const lastDayOfWeek = endDay.weekday;
    for (let i = lastDayOfWeek + 1; i <= 7; i++) {
      days.push({ isPlaceholder: true });
    }

    return days;
  }

  public isSelected(date: DateTime): boolean {
    return this.selectedTimeSlots.some((slot) => slot.date.equals(date));
  }

  public nextMonth(): void {
    if (!this.nextMonthDisabled()) {
      this.currentMonth = this.currentMonth.plus({ months: 1 });
    }
  }

  public previousMonth(): void {
    const now = DateTime.now();
    if (this.currentMonth.minus({ months: 1 }).startOf('month') >= now.startOf('month')) {
      this.currentMonth = this.currentMonth.minus({ months: 1 });
    }
  }

  public nextMonthDisabled(): boolean {
    const maxMonth = DateTime.now().plus({ months: 3 }).endOf('month');
    return this.currentMonth.endOf('month') >= maxMonth;
  }

  public selectDate(day: DateTime): void {
    if (day >= DateTime.now().startOf('day')) {
      const dialogRef = this._dialog.open(TimeSlotDialogComponent, {
        data: { selectedDate: day, isDriveIn: this.selectedBookingType === 'Drive-In' },
        maxWidth: '90vw',
        height: 'auto',
        maxHeight: '90vh'
      });

      const dialogSubscription = dialogRef.afterClosed().subscribe((result: selectedDateTimeSlot) => {
        if (result) {
          this.selectedTimeSlots = this.selectedTimeSlots.filter((slot) => !slot.date.equals(day));
          this.selectedTimeSlots.push(result);
          this._emitSelection();
        }
      });

      // Add the subscription to the composite subscription
      this._subscription.add(dialogSubscription);
    }
  }

  public removeDate(index: number): void {
    this.selectedTimeSlots.splice(index, 1); // Remove the date at the specified index
  }

  public removeTimeSlot(dateIndex: number, timeSlotIndex: number): void {
    this.selectedTimeSlots[dateIndex].timeSlots.splice(timeSlotIndex, 1); // Remove the timeslot
    if (this.selectedTimeSlots[dateIndex].timeSlots.length === 0) {
      this.removeDate(dateIndex); // Optionally remove the date if no timeslots are left
    }
  }

  protected readonly faChevronUp = faChevronUp;
  protected readonly faChevronDown = faChevronDown;

  public isPlaceholder(day: DateTime | { isPlaceholder: boolean }): day is { isPlaceholder: boolean } {
    return (day as { isPlaceholder: boolean }).isPlaceholder !== undefined;
  }

  public previousMonthDisabled(): boolean {
    const now = DateTime.now();
    return this.currentMonth.minus({ months: 1 }).startOf('month') < now.startOf('month');
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
